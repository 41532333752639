[appCollapsibleElement] {
  @apply h-0 overflow-hidden transition-height;

  &.-opened {
    @apply h-auto;
  }
}

[appGradientHidden] {
  @apply overflow-hidden transition-height relative cursor-pointer;

  &.-displayed {
    @apply cursor-auto h-auto;

    .gradient_mask {
      @apply opacity-0 pointer-events-none;
    }
  }

  .gradient_mask {
    @apply absolute top-0 bottom-0 right-0 left-0 z-1 transition-opacity;

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 50%, var(--theme-white) 100%);
  }

  &.-gray {
    .gradient_mask {
      background: linear-gradient(
        to bottom,
        var(--transparent) 0%,
        var(--site-theme-main-five) 100%
      );
    }
  }
}
