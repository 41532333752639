.spinner_element_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_100_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_button_container {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
