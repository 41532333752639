body.-mobile-browser {
  .modal-inside,
  app-modal {
    &:after {
      content: '';
      display: block;
      height: 100px;
    }
  }

  .content-question-challenge.-in-candidate {
    @apply pb-[130px];
  }

  .end-challenge-modal-middle {
    @apply pb-36;
  }
}
