// nnkitodo [v2later css]

.router-container {
  @apply opacity-100 transition-opacity;

  &.-component-loading {
    @apply opacity-0 transition-none;
  }
}

.logged-area-container {
  @apply pt-navbarHeight min-h-screen overflow-hidden;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    @apply pt-navbarSmallHeight;
  }
}

.public-area-container {
  // @apply pt-publicNavbarHeight;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    // @apply pt-publicNavbarSmallHeight;
  }
}

.component-container {
  padding: 20px;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    // @apply pb-bottombarHeight;
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }

  &.-withMobileButton {
    @media (max-width: $screen-xs-max) {
      padding-bottom: calc(#{theme('spacing.bottombarHeight')} + 120px) !important;
    }
  }
}

.inside-container {
  @apply px-4 sm:px-0;
}
