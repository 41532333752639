.container--user {
  @apply w-[1100px] max-w-full px-16 mx-auto relative z-1 py-12;
}

.card--user {
  @apply sm:w-[450px] max-w-[450px] flex flex-col items-stretch;

  .card--user__title {
    @apply font-landing text-41 sm:text-65 font-bold mb-16 sm:mb-12 sm:whitespace-nowrap sm:w-max text-site-main-one;

    &:first-line,
    > div:first-line {
      @apply text-theme-black;
    }
  }
}
