button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
}

.btn-nnki {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;

  font-size: 1.3rem;
  font-weight: bold;

  line-height: initial;
  white-space: nowrap;

  padding: 0 15px;

  height: 40px;

  cursor: pointer;

  &[disabled] {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &.-disabled {
    cursor: auto;
    opacity: 0.5;
  }
}

.btn-nnki-100 {
  width: 100%;
}

.btn-nnki-primary {
  background: var(--site-main-one);
  color: var(--app-white);

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-purple {
  background: var(--theme-purple-two);
  color: var(--app-white);

  &:hover {
    color: var(--theme-white);
  }
}

.btn-nnki-white {
  background: var(--theme-white);
  color: var(--site-theme-accent);
  border: 1px solid var(--site-theme-accent);
}

.btn-nnki-light {
  background: var(--site-theme-main-five);
  color: var(--theme-black);
  border: 1px solid var(--site-theme-main-five);

  &:hover {
    color: var(--theme-black);
  }

  svg-icon {
    color: var(--site-main-one);
  }
}

.btn-nnki-black {
  background: var(--theme-black);
  color: var(--theme-white);
  border: 1px solid var(--theme-black);

  &:hover {
    color: var(--theme-white);
  }
}

.btn-nnki-red {
  background: var(--app-red-two);
  color: var(--app-white);
  border: 1px solid var(--app-red-two);

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-red-three {
  @apply bg-red-three text-white border border-red-three;

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-orange-two {
  @apply bg-orange-two text-white border border-orange-two;

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-green {
  background: var(--app-green-three);
  color: var(--app-white);
  border: 1px solid var(--app-green-three);

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-gold {
  background: var(--app-gold);
  color: var(--app-black);
  border: 1px solid var(--app-gold);
}

.btn-nnki-gradient {
  background-image: linear-gradient(
    24deg,
    var(--site-card-gradient-1) 0%,
    var(--site-card-gradient-2) 100%
  );
  color: var(--app-white);

  &:hover {
    color: var(--app-white);
  }
}

.btn-nnki-transparent {
  background: transparent;
  color: var(--site-main-one);
  border: 1px solid transparent;

  &:hover {
    color: var(--site-main-one);
  }
}

.btn-nnki-transparent-with-border {
  background: transparent;
  color: var(--site-main-one);
  border: 1px solid var(--site-main-one);

  &:hover {
    color: var(--site-main-one);
  }
}

.btn-nnki-uppercase {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.btn-nnki-heavy {
  font-weight: 800;
}

.btn-nnki-rounded {
  border-radius: 18px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 1rem;
}

.btn-nnki-card {
  font-size: 1rem;
  height: 36px;
  min-width: 120px;
  text-transform: uppercase;

  @media (max-width: $screen-xs-max) {
    min-width: 100px;
  }
}

.btn-nnki-small {
  font-size: 1.1rem;
  font-weight: normal;
  height: 30px;

  &.btn-nnki-icon {
    width: 30px;
  }
}

.btn-nnki-icon {
  padding: 0;
  width: 40px;
}

.btn-nnki-icon-left {
  svg-icon {
    margin-right: 15px;
  }
}

.btn-nnki-icon-right {
  svg-icon {
    margin-left: 15px;
  }
}

.btn-nnki-icon-both {
  svg-icon:first-child {
    margin-right: 15px;
  }
  svg-icon:last-child {
    margin-left: 15px;
  }
}

.btn-nnki-circular {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  color: var(--site-theme-accent);
  border: 1px solid var(--site-theme-accent);
  border-radius: 50%;
  font-weight: normal;
  padding: 0;

  &.-no-border {
    border: 0;
  }
}

.btn-nnki-action {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  color: var(--site-main-one);
  background: var(--site-main-six);
  @apply rounded;

  &.-no-border {
    border: 0;
  }
}

.btn-nnki-tab {
  height: 40px;
  border-radius: 50px;
  @apply shadow;
  background-color: var(--theme-white);

  font-size: 1.3rem;

  padding: 0 20px;

  svg-icon {
    margin-right: 10px;
    color: var(--site-main-one);
  }

  @media (max-width: $screen-xs-max) {
    background: var(--site-main-one);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 0;

    > svg-icon {
      margin: 0;
      width: 33px;
      height: auto;
      color: var(--theme-white);
    }
    > span {
      display: none;
    }
  }

  &.-withoutText {
    box-shadow: none;
    background: transparent;
    margin-right: 0 !important;
    svg-icon {
      margin-right: 0;
    }
  }
}

.btn-nnki-dummy {
  background: transparent;
  pointer-events: none;
}

.mat-ripple:not(:empty) {
  transform: none;
}
