.block-with-slider {
  padding-left: 0px;
  padding-right: 0px;

  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 20px;

  position: relative;

  .swiper-container {
    padding-top: 20px;
    padding-bottom: 50px;

    padding-left: 20px;
    padding-right: 20px;
  }

  .card {
    margin-bottom: 0;
  }
}

.swiper-nav {
  margin-left: auto;
  display: flex;

  svg-icon {
    color: var(--theme-black);
  }

  &.-white {
    svg-icon {
      color: var(--theme-white);
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: static;
  width: auto;
  height: auto;
  margin-left: 10px;
  margin-top: 0;
  padding: 5px;

  &:after {
    content: '';
    background: none;
  }
}

.swiper-pag {
  display: flex;
  justify-content: center;
  margin-top: -30px;
  position: relative;
  @apply z-1;

  .swiper-pagination-bullet {
    margin: 0 3px;

    @media (min-width: $screen-sm) {
      display: none;
    }
  }
}

.swiper-wrapper {
  align-items: stretch;
  height: auto;
}

.swiper-slide {
  width: 470px;
  max-width: 100%;
  height: auto;

  &.-margin {
    width: 100px;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &.-exam {
    width: 470px;
  }

  &.-training {
    width: 450px;
  }

  &.-video {
    width: 360px;
  }

  &.-image {
    width: 300px;
  }

  &.-discipline {
    width: 300px;
  }

  &.-disciplineChallenge {
    width: 380px;

    @media (max-width: $screen-md-max) {
      width: 340px;
    }

    @media (max-width: $screen-xs-max) {
      width: 300px;
    }

    app-discipline-rank {
      display: block;
      margin-bottom: 35px;
    }
  }

  &.-major {
    width: 100%;
  }

  &.-userList {
    @apply w-32 block;
  }
}

.swiper-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  background-image: linear-gradient(90deg, var(--transparent) 0%, var(--theme-white) 100%);
  @apply z-1;
  pointer-events: none;

  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
