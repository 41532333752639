.google-visualization-tooltip-item-list {
  > :first-child {
    display: none;
  }
}

ngx-extended-pdf-viewer #mainContainer {
  min-width: 0 !important;
}

editor {
  width: 100%;
}

.tox-tinymce-aux {
  @apply z-editor #{!important};
}
