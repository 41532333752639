.filters-panel {
  @apply fixed sm:absolute sm:w-[400px] top-0 bottom-0 right-0 left-0 sm:left-auto sm:bottom-auto sm:max-h-[calc(100vh-90px-)] overflow-auto bg-theme-white pt-12 pb-28 sm:py-12 sm:px-8 text-theme-black sm:rounded sm:shadow z-panels;

  @screen sm {
    max-height: calc(
      100vh - 90px - var(--safe-area-inset-bottom) - var(--safe-area-inset-top)
    ) !important;
  }

  &:before {
    content: '';
    @apply block sm:hidden h-safeTop;
  }

  &:after {
    content: '';
    @apply block sm:hidden h-safeBottom;
  }

  .spinner_100_container {
    @apply my-20;
  }
}

.mask-filters {
  @apply fixed top-0 bottom-0 right-0 left-0;
  z-index: calc(#{theme('zIndex.panels')} - 1);
}

.filters-close {
  @apply absolute right-0 top-4 p-8 cursor-pointer;

  &:before {
    content: '';
    @apply block sm:hidden h-safeTop;
  }
}

.filters-title {
  @apply px-8 sm:px-0 text-20 font-bold mb-10;
}

.filters-reset {
  @apply cursor-pointer text-right text-site-main-one text-13 font-bold mb-6;

  &.-hidden {
    @apply pointer-events-none opacity-0;
  }
}

.filter-blocks {
  @apply space-y-6;
}

.filter-block {
  @apply bg-site-theme-main-five rounded-card py-8 px-12;

  .filter-title {
    @apply text-16 font-bold text-site-main-one mb-8;
  }

  .mat-form-field-appearance-legacy {
    @apply bg-theme-white #{!important};
  }
}

.filters-submit {
  @apply fixed left-0 right-0 bottom-0 p-6 bg-theme-white sm:hidden shadow;

  &:after {
    content: '';
    @apply block h-safeBottom;
  }
}
