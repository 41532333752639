@mixin buttonToggleWrapAt($wrapAt) {
  @media (max-width: $wrapAt) {
    flex-wrap: wrap;
    width: 100%;

    .mat-button-toggle {
      border-left-width: 1px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 4px;
    }
  }
}

@mixin circleDeco($top, $right, $bottom, $left, $diameter, $borderWidth, $opacity) {
  border-radius: 50%;
  background: transparent;
  opacity: $opacity;
  border: $borderWidth solid var(--app-white);
  width: $diameter;
  height: $diameter;
  position: absolute;
  left: $left;
  top: $top;
  bottom: $bottom;
  right: $right;
}
