// colors

:root {
  --app-white: #ffffff;
  --app-black: #000000;

  --app-gray-six: rgba(0, 62, 179, 0.5);
  --app-gray-twenty: rgba(0, 0, 0, 0.5);
  --app-gray-twentyfive: rgba(0, 0, 0, 0.6);
  --app-gray-twentynine: #9ea0a5;
  --app-gray-one: #161616;

  --app-red-one: #fc4747;
  --app-red-two: #f10b28;
  --app-red-three: #dc4949;
  --app-red-four: #ff2800;

  --app-green-one: #03d1bd;
  --app-green-three: #0ec164;
  --app-green-five: #429321;
  --app-green-six: #b4ec51;

  --app-yellow-one: #eeb735;
  --app-yellow-two: rgba(255, 248, 210, 0.4);
  --app-yellow-three: #ffd135;

  --app-purple-one: #be4bdb;
  --app-purple-three: #f0e3fd;

  --app-blue-one: #2196f3;
  --app-blue-four: rgba(0, 84, 242, 0.18);
  --app-blue-five: rgba(0, 84, 242, 0.5);
  --app-blue-six: rgba(0, 84, 242, 0.12);
  --app-blue-seven: #0054f2;

  --app-orange-one: #f74e13;
  --app-orange-two: #eeb635;
  --app-orange-three: #ffa03b;

  --app-gold: #dfc271;

  --app-color-free: #14c8b1;
  --app-color-premium: #0054f2;
  --app-color-premiumplus: #000000;

  --app-color-facebook: #3e6ad6;

  --app-free-gradient-1: #49dc50;
  --app-free-gradient-2: #0cc6bf;
  --app-premiumplus-gradient-1: #fad961;
  --app-premiumplus-gradient-2: #f76b1c;
  --app-elite-gradient-1: #000000;
  --app-elite-gradient-2: #313131;

  --transparent: rgba(255, 255, 255, 0.0000001);

  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }

  --safe-intercom: 0px;
  @screen sm {
    --safe-intercom: 100px;
  }
}

.light-theme-mode {
  --theme-black: #000000;
  --theme-white: #ffffff;

  --theme-gray-one: #161616;
  --theme-gray-two: #666666;
  --theme-gray-three: #e6e6e6;
  --theme-gray-four: #7a838f;
  --theme-gray-five: #969696;
  --theme-gray-seven: #e4e8ea;
  --theme-gray-eight: #dadfe4;
  --theme-gray-nine: #505050;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #c6cbd4;
  --theme-gray-twelve: #434b50;
  --theme-gray-thirteen: #0e0e0e;
  --theme-gray-fourteen: #bdbdbd;
  --theme-gray-fifteen: #dbe8ff;
  --theme-gray-sixteen: #c8c8c8;
  --theme-gray-seventeen: #fafafa;
  --theme-gray-eighteen: #efefef;
  --theme-gray-nineteen: rgba(0, 0, 0, 0.1);
  --theme-gray-twentyone: rgba(255, 255, 255, 0.9);
  --theme-gray-twentytwo: rgba(255, 255, 255, 0.33);
  --theme-gray-twentythree: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyfour: rgba(0, 0, 0, 0.7);
  --theme-gray-twentysix: rgba(158, 160, 165, 0.7);
  --theme-gray-twentyseven: #cedffc;
  --theme-gray-twentyeight: rgba(0, 0, 0, 0.24);
  --theme-gray-twentynine: rgba(255, 255, 255, 0.8);

  --theme-purple-two: #3a2080;

  --theme-blue-two: rgba(46, 48, 64, 0.3);
  --theme-blue-three: rgba(46, 48, 64, 0.2);
}

.dark-theme-mode {
  --theme-black: #ffffff;
  --theme-white: #303030;

  --theme-gray-one: #e9e9e9;
  --theme-gray-two: #999999;
  --theme-gray-three: #191919;
  --theme-gray-four: #707985;
  --theme-gray-five: #969696;
  --theme-gray-seven: #1b1715;
  --theme-gray-eight: #1b1f25;
  --theme-gray-nine: #afafaf;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #d8d8d8;
  --theme-gray-twelve: #afb7bc;
  --theme-gray-thirteen: #f1f1f1;
  --theme-gray-fourteen: #424242;
  --theme-gray-fifteen: #000f24;
  --theme-gray-sixteen: #373737;
  --theme-gray-seventeen: #050505;
  --theme-gray-eighteen: #101010;
  --theme-gray-nineteen: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyone: rgba(48, 48, 48, 0.9);
  --theme-gray-twentytwo: rgba(48, 48, 48, 0.33);
  --theme-gray-twentythree: rgba(48, 48, 48, 0.1);
  --theme-gray-twentyfour: rgba(255, 255, 255, 0.7);
  --theme-gray-twentysix: rgba(90, 91, 97, 0.7);
  --theme-gray-twentyseven: #032031;
  --theme-gray-twentyeight: rgba(255, 255, 255, 0.24);
  --theme-gray-twentynine: rgba(48, 48, 48, 0.8);

  --theme-purple-two: #8d6ce6;

  --theme-blue-two: rgba(191, 195, 209, 0.3);
  --theme-blue-three: rgba(191, 195, 209, 0.2);
}

.ecni-theme-site {
  --site-main-one: #0054f2;
  --site-main-two: rgb(236, 247, 255);
  --site-main-five: #f6f9fe;
  --site-main-six: rgba(43, 126, 251, 0.08);

  --site-light-card: #267bfb;
  --site-dark-card: #2013b5;

  --site-card-gradient-1: #297af6;
  --site-card-gradient-2: #4fc8fd;

  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;

  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;

  --site-section-3-gradient-1: #008dfa;
  --site-section-3-gradient-2: #0054f2;

  --site-section-4-gradient-1: #297af6;
  --site-section-4-gradient-2: #4fc8fd;

  --site-public-gradient-1: rgba(0, 144, 255, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);

  --site-oval-challenge-list: #ac91f6;

  --site-logo-1: rgba(0, 84, 242, 0.9);
  --site-logo-2: rgba(255, 91, 27, 0.9);
  --site-logo-3: rgba(153, 206, 37, 0.9);
  --site-logo-4: rgba(238, 183, 53, 0.9);

  &.light-theme-mode {
    --site-theme-main-two: #dbe8ff;
    --site-theme-main-three: #f7f9ff;
    --site-theme-main-four: #ebf3fe;
    --site-theme-main-five: #f6f9fe;

    --site-theme-accent: var(--site-main-one);
  }

  &.dark-theme-mode {
    --site-theme-main-two: #000a24;
    --site-theme-main-three: #000308;
    --site-theme-main-four: #010b14;
    --site-theme-main-five: #010409;

    --site-theme-accent: var(--app-white);
  }

  // LANDING V2
  --site-main-landing: #067cff;
  --tagline: #067cff;

  --caption-gradient-1: #4292cb;
  --caption-gradient-2: #0b0002;
  --caption-gradient-3: #77003b;

  --numbers-gradient-1: #067cff;
  --numbers-gradient-2: #50bfff;

  --avatar-count: #50bfff;
  --ttl-tools: #134a95;
  --ttl-tagline: #067cff;

  --bs-primary: #067cff;
  --bs-secondary: #0e2c5d;
}

.prepssm-theme-site {
  --site-main-one: #0ec164;
  --site-main-two: rgb(239, 251, 245);
  --site-main-five: #f2fffd;
  --site-main-six: rgba(14, 193, 100, 0.08);

  --site-light-card: #17d071;
  --site-dark-card: #006139;

  --site-card-gradient-1: #0cc6bf;
  --site-card-gradient-2: #49dc50;

  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;

  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;

  --site-section-3-gradient-1: #0ec164;
  --site-section-3-gradient-2: #03d1bd;

  --site-section-4-gradient-1: #0cc6bf;
  --site-section-4-gradient-2: #49dc50;

  --site-public-gradient-1: rgba(38, 199, 115, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);

  --site-oval-challenge-list: #ac91f6;

  --site-logo-1: rgba(14, 193, 100, 0.9);
  --site-logo-2: rgba(74, 233, 150, 0.9);
  --site-logo-3: rgba(14, 193, 100, 0.9);
  --site-logo-4: rgba(255, 91, 27, 0.9);

  &.light-theme-mode {
    --site-theme-main-two: #d7f2e6;
    --site-theme-main-three: #f3fff3;
    --site-theme-main-four: #dcffeb;
    --site-theme-main-five: #f2fffd;

    --site-theme-accent: var(--site-main-one);
  }

  &.dark-theme-mode {
    --site-theme-main-two: #22564b;
    --site-theme-main-three: #030c00;
    --site-theme-main-four: #032300;
    --site-theme-main-five: #000d0b;

    --site-theme-accent: var(--app-white);
  }

  // LANDING V2
  --site-main-landing: #17d070;
  --tagline: #c75507;

  --caption-gradient-1: #105734;
  --caption-gradient-2: #0b0002;
  --caption-gradient-3: #c75507;

  --numbers-gradient-1: #16a349;
  --numbers-gradient-2: #4ade80;

  --avatar-count: #17d070;
  --ttl-tools: #105734;
  --ttl-tagline: #ff8906;

  --bs-primary: #17d070;
  --bs-secondary: #0ec164;
}

.prepmir-theme-site {
  --site-main-one: #d32030;
  --site-main-two: rgb(255, 251, 238);
  --site-main-five: #fffcf2;
  --site-main-six: rgba(248, 251, 43, 0.08);

  --site-light-card: #ffd135;
  --site-dark-card: #d31d2d;

  --site-card-gradient-1: #ffac56;
  --site-card-gradient-2: #ffd135;

  --site-section-gradient-1: #ffd135;
  --site-section-gradient-2: #ffac56;

  --site-section-2-gradient-1: #ffac56;
  --site-section-2-gradient-2: #ffd135;

  --site-section-3-gradient-1: #cf7a22;
  --site-section-3-gradient-2: #ffd135;

  --site-section-4-gradient-1: #f6aa36;
  --site-section-4-gradient-2: #ed7f0b;

  --site-public-gradient-1: #fff7dc;
  --site-public-gradient-2: #ffffff;

  --site-oval-challenge-list: #ef9545;

  --site-logo-1: rgba(211, 32, 48, 0.9);
  --site-logo-2: rgba(255, 209, 53, 0.9);
  --site-logo-3: rgba(211, 32, 48, 0.9);
  --site-logo-4: rgba(233, 182, 7, 0.9);

  &.light-theme-mode {
    --site-theme-main-two: #ffe7ab;
    --site-theme-main-three: #fffef7;
    --site-theme-main-four: #fff4cf;
    --site-theme-main-five: #fffcf2;

    --site-theme-accent: var(--site-main-one);
  }

  &.dark-theme-mode {
    --site-theme-main-two: #543c00;
    --site-theme-main-three: #080600;
    --site-theme-main-four: #301e00;
    --site-theme-main-five: #0d0a00;

    --site-theme-accent: var(--app-white);
  }

  // LANDING V2
  --site-main-landing: #d32030;
  --tagline: #d32030;

  --caption-gradient-1: #988f73;
  --caption-gradient-2: #0b0002;
  --caption-gradient-3: #77003b;

  --numbers-gradient-1: #d32030;
  --numbers-gradient-2: #f99807;

  --avatar-count: #fda4ac;
  --ttl-tools: #d32030;
  --ttl-tagline: #d32030;

  --bs-primary: #d32030;
  --bs-secondary: #a91a26;
}

// screens

$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
