.landing-subtitle {
  @apply font-landing font-semibold leading-none text-center mx-auto;

  strong {
    @apply font-extrabold;
  }
}

.landing-subtitle-centered {
  @extend .landing-subtitle;
  @apply leading-none text-center mx-auto;
}

.landing-subtitle-dash {
  @extend .landing-subtitle;
  @apply leading-tight text-40 text-center relative mb-20;

  @screen md {
    @apply text-left;
  }

  &:before {
    content: '';
    @apply w-4 h-24 rounded bg-site-main-one absolute -left-14 top-0;
  }
}

.landing-gradient {
  @apply absolute left-0 right-0 bottom-0 h-40;
}

.testimony-block {
  @apply flex flex-col items-center w-full;

  @screen md {
    @apply items-start w-[420px];
  }

  .testimony-title {
    @apply font-bold text-20 mb-14 text-center;
    grid-area: testimony-title;

    ::ng-deep strong {
      @apply font-extrabold;
    }

    @screen md {
      @apply text-left;
    }
  }

  .testimony-separator {
    @apply border-t border-dashed border-theme-gray-five w-full mb-12 md:mr-auto;
    grid-area: testimony-separator;

    @screen md {
      @apply w-[388px];
    }
  }

  .testimony-text {
    @apply italic text-15 mb-12;
    grid-area: testimony-text;
  }

  .testimony-author {
    @apply flex items-center mr-auto;
    grid-area: testimony-author;

    .testimony-author-icon {
      @apply mr-12 flex-shrink-0;
    }

    .testimony-author-content {
      @apply grow font-landing font-semibold text-20;

      .testimony-author-position {
        @apply opacity-70;
      }
    }
  }
}

.section-ancrage {
  @apply pb-40 md:pb-80;

  &.-onLanding {
    background-image: linear-gradient(to top, var(--site-theme-main-four), var(--theme-white));
  }

  .container--landing {
    @apply grid gap-x-8 md:gap-x-24 justify-items-center items-center;

    grid-template-areas:
      'subtitle'
      'testimony-title'
      'image'
      'testimony-separator'
      'testimony-text'
      'testimony-author';
    grid-template-columns: 100%;

    @screen md {
      grid-template-areas:
        'subtitle subtitle'
        'image testimony-title'
        'image testimony-separator'
        'image testimony-text'
        'image testimony-author';
      grid-template-columns: minmax(0, 1fr) 445px;
    }
  }

  .landing-subtitle-centered {
    grid-area: subtitle;
    @screen md {
      width: 625px;
    }
  }

  .ancrage-image {
    grid-area: image;
    width: 555px;
  }

  .testimony-block {
    @apply contents;

    .testimony-separator {
      @apply mt-12 md:mt-0;
    }
  }

  .landing-gradient {
    background: linear-gradient(
      -177deg,
      var(--transparent) 0%,
      var(--transparent) 50%,
      var(--theme-white) 50%,
      var(--theme-white) 100%
    );
  }
}

.section-screenshot {
  @apply pt-12 pb-32 md:py-32;

  .container--landing {
    @apply grid gap-x-8 md:gap-x-24 justify-items-center items-center;

    grid-template-areas:
      'subtitle'
      'testimony-title'
      'image'
      'testimony-separator'
      'testimony-text'
      'testimony-author';
    grid-template-columns: 100%;

    @screen md {
      grid-template-areas:
        'subtitle image'
        'testimony-title image'
        'testimony-separator image'
        'testimony-text image'
        'testimony-author image';
      grid-template-columns: 445px minmax(0, 1fr);
    }

    .landing-subtitle-dash {
      grid-area: subtitle;
    }

    .screenshot-image {
      grid-area: image;
      @apply max-w-full flex justify-start;

      @screen md {
        width: 636px;
      }

      .screenshot-inside {
        @apply shadow rounded max-w-none;
      }
    }

    .testimony-block {
      @apply contents;

      .testimony-separator {
        @apply mt-12 md:mt-0;
      }
    }
  }

  &.-inversed {
    @apply bg-site-theme-main-four;

    .container--landing {
      @screen md {
        grid-template-areas:
          'image subtitle'
          'image testimony-title'
          'image testimony-separator'
          'image testimony-text'
          'image testimony-author';
        grid-template-columns: minmax(0, 1fr) 445px;
      }

      .screenshot-image {
        @apply md:justify-end;
      }
    }
  }

  .landing-gradient {
    background: linear-gradient(
      -3deg,
      var(--site-theme-main-four) 0%,
      var(--site-theme-main-four) 50%,
      var(--transparent) 50%,
      var(--transparent) 100%
    );
  }
}
