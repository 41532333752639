// nnkitodo [v2later css] : plus propre !

.-mobile-device {
  app-logged-bottom-nav {
    display: block;

    &.-sidebarOpened {
      transform: translateY(80px);
      opacity: 0;
      pointer-events: none;
    }
  }

  app-logged-top-nav {
    left: 0;
    height: calc(var(--safe-area-inset-top) + #{theme('spacing.navbarSmallHeight')});
    justify-content: start;
    padding-left: 10px;
    padding-right: 10px;

    app-site-logo {
      display: block;
      margin-right: 20px;
    }

    .navbar-title {
      margin-right: 20px;
    }

    .navbar-search {
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }

    .search-mask {
      display: block;
    }

    .navbar-buttons {
      margin-left: auto;
      .navbar-button {
        margin: 0 8px;

        &.-mobileOnly {
          display: block !important;
        }
      }
    }

    .navbar-user {
      display: none;
    }

    .navbar-hamburger {
      display: block;
    }
  }

  app-logged-side-nav {
    nav.sidenav {
      @media (min-width: $screen-sm) {
        width: 350px !important;
        max-width: 100vw !important;
      }
    }

    .sidenav-close {
      display: block !important;
    }

    .icon-container {
      justify-content: start !important;
      padding-left: 20px !important;
    }

    .sidenav_desktop {
      @media (min-width: $screen-md) {
        display: none;
      }
    }

    .sidenav_mobile {
      display: block !important;
    }

    .navbar-user {
      display: block !important;
    }

    .version-number {
      @media (min-width: $screen-md) {
        display: block !important;
      }
    }
  }

  app-subscription-card,
  .sidenav_premium_prolonger,
  .sidenav_premium_convert {
    @media (min-width: $screen-sm) {
      display: none !important;
    }
  }

  .sidenav_premium_mobile {
    @media (min-width: $screen-sm) {
      display: flex !important;
    }
  }

  .screen_height {
    @media (min-width: $screen-sm) {
      height: calc(100vh - 215px);
    }
  }

  .navbar-search {
    position: absolute;
    left: 0;
    width: 100%;

    z-index: calc(#{theme('zIndex.topNav')} - 1);
    background: var(--theme-white);
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    @apply transition-spacing top-navbarSmallHeight;

    border-radius: 0;

    box-shadow: none;
    border: 0;

    &.-active {
      height: calc(var(--safe-area-inset-top) + 60px);
      padding-top: calc(var(--safe-area-inset-top) + 20px);
      padding-bottom: 20px;
    }

    .form-search {
      width: 100%;
      border-radius: 40px;

      .form-input {
        color: var(--theme-black);
        margin-right: 0;
        width: 100%;

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: var(--theme-gray-twentyfour);
        }

        &:-moz-placeholder,
        &::-moz-placeholder {
          color: var(--theme-black);
          opacity: 0.7;
        }
      }
    }
  }

  .module-badge {
    line-height: 20px;
    width: 19px;
    height: 19px;
    font-size: 10px;
  }

  .logged-area-container {
    @apply pt-navbarSmallHeight;
  }

  .component-container {
    @apply pb-bottombarHeight;
  }

  .fixed-inSession-logo {
    display: none;
  }

  .filters-panel {
    @screen sm {
      max-height: calc(
        100vh - 90px - var(--safe-area-inset-bottom) - var(--safe-area-inset-top) - 60px
      ) !important;
    }
  }

  .tabs-group-nnki,
  .tabs-nnki {
    &.tabs-hidden-on-desktop {
      visibility: visible !important;
      .mat-ink-bar {
        visibility: visible !important;
      }
    }
  }
}
