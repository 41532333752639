.form-v2-nnki,
.form-nnki,
.row-form-nnki {
  label {
    @apply font-normal font-avenir text-14;
  }

  mat-form-field {
    @apply bg-site-theme-main-five py-3 px-4 rounded w-full;

    .mat-form-field-wrapper {
      @apply pb-0;
    }

    .mat-form-field-infix {
      @apply w-auto border-t-[8px];
    }

    .mat-input-element {
      @apply text-site-main-one text-16 font-extrabold font-default;
    }

    .mat-form-field-label-wrapper {
      @apply top-[-17px];
    }

    .mat-form-field-label {
      @apply font-default text-16 font-extrabold;
    }

    .mat-form-field-suffix {
      @apply text-site-main-one;
    }

    .mat-form-field-underline {
      @apply hidden;
    }

    .mat-form-field-subscript-wrapper {
      @apply pointer-events-none z-1 bottom-[-30px] top-auto overflow-visible;
    }

    &.mat-form-field-should-float {
      .mat-form-field-label {
        @apply top-[24px] text-14 font-normal;
      }
    }

    &.mat-form-field-invalid {
      @apply border border-red-one;

      .mat-input-element,
      .mat-form-field-suffix {
        @apply text-red-one;
      }
    }

    &.mat-form-field-disabled {
      @apply opacity-50;
    }
  }

  mat-checkbox {
    .mat-checkbox-layout {
      @apply items-start whitespace-normal font-default;
    }

    .mat-checkbox-inner-container {
      @apply mr-6 mt-2 mb-0;
    }

    + mat-error {
      @apply hidden;
    }
  }

  mat-select {
    .mat-select-value-text {
      @apply text-site-main-one text-16 font-extrabold;
    }
  }

  &.ng-submitted {
    mat-checkbox + mat-error {
      @apply block;
    }
  }
}

.error-tooltip {
  @apply z-1 relative ml-auto rounded bg-red-one text-white text-14 px-6 py-2 max-w-[80%] pointer-events-none #{!important};

  &:before {
    content: '';

    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent var(--app-red-one) transparent;

    @apply absolute top-[-6px] left-8 w-0 h-0 z-2;
  }
}

.success-tooltip {
  @extend .error-tooltip;
  @apply bg-green-three #{!important};

  &:before {
    border-color: transparent transparent var(--app-green-three) transparent;
  }
}

.form-nnki-public {
  .form-field-nnki.mat-form-field-appearance-legacy {
    box-shadow: 0 10px 37px 0 #e5f0ff;
    border: solid 1px var(--theme-gray-fifteen);
    background-color: var(--theme-gray-twentynine);
    padding-bottom: 4px;

    label {
      font-size: 1.4rem;
      font-weight: normal;
    }

    &:not(.mat-form-field-invalid) {
      label {
        color: var(--theme-gray-two);
      }
    }

    &.mat-form-field-invalid {
      border-color: var(--app-red-one);
    }

    .mat-form-field-suffix {
      color: var(--site-main-one);
    }
  }

  .btn-nnki-100 {
    height: 45px;

    svg-icon {
      margin-left: 45px;
    }
  }
}

.row-form-nnki {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  margin-bottom: 10px;

  @media (max-width: $screen-xs-max) {
    flex-wrap: wrap;

    &.-nowrap {
      flex-wrap: nowrap;
    }
  }

  &.-alignItemsCenter {
    align-items: center;
  }

  &.-center_align {
    justify-content: center;
  }

  &.-right_align {
    justify-content: flex-end;
  }

  &.-wrapButton {
    align-items: center;

    @media (max-width: $screen-xs-max) {
      flex-wrap: nowrap;
    }

    .form-field-nnki-container {
      margin: 0;
      height: 40px;
    }

    textarea {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      padding: 10px 20px;
      font-size: 1.6rem;
      background: var(--theme-gray-eighteen);
      color: var(--theme-black);
      border: 0;
      resize: none;
    }

    .btn-nnki {
      margin-left: 10px;
      flex-shrink: 0;
    }
  }

  .alert {
    width: 100%;
  }
}

.width50 {
  width: calc(50% - 5px);

  @media (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.width100 {
  width: 100%;
}

.form-message-nnki {
  padding: 0 5px;
  font-size: 1.2rem;
  &.-error {
    color: var(--app-orange-one);
  }

  &.-success {
    color: var(--app-green-three);
  }

  &.-info {
    color: var(--theme-gray-five);
  }
}

.input-nnki,
.input-nnki.mat-input-element {
  padding: 0 35px;
  height: 60px;
  @apply rounded;
  @apply shadow;
  border: solid 1px var(--theme-gray-fifteen);
  background-color: var(--theme-white);
  color: var(--theme-black);
  font-size: 1.6rem;

  &.-small {
    height: 40px;
    padding: 0 15px;
  }

  @media (max-width: $screen-xs-max) {
    padding: 0 15px;
  }

  &[type='text'],
  &[type='email'] {
    -webkit-user-select: auto;
  }
}

.textarea-nnki {
  padding: 20px 35px;
  height: 120px;
  @apply rounded;
  @apply shadow;
  border: solid 1px var(--theme-gray-fifteen);
  background-color: var(--theme-white);
  resize: none;

  @media (max-width: $screen-xs-max) {
    padding: 15px;
  }
}

.mat-radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.-allWrapped {
    > * {
      width: 100%;
    }
  }

  &.-inline {
    .mat-radio-button {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      display: block;

      > * {
        display: block;
      }

      .mat-radio-button {
        margin-right: 0px;
      }
    }
  }

  &.-submitted.ng-invalid {
    .mat-radio-outer-circle {
      border-color: var(--app-red-one);
    }
    .mat-radio-label-content {
      color: var(--app-red-one);
    }
  }

  label {
    font-weight: normal;
  }
}

mat-radio-button.mat-radio-button {
  &.mat-radio-checked {
    label {
      font-weight: bold;
    }
  }

  label {
    font-weight: normal;
  }

  .mat-radio-label {
    white-space: normal;
    align-items: flex-start;
  }
  .mat-radio-container {
    top: -1px;
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.6);
  }

  .mat-radio-outer-circle {
    border-width: 1px;
    border-color: var(--theme-gray-eleven);
    transform: scale(0.6);
  }
}

.form-field-nnki.mat-form-field-type-mat-select {
  &.mat-form-field-appearance-legacy.form-field-select {
    height: 58px;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-select-arrow-wrapper {
      padding-bottom: 10px;
      top: -5px;
      position: relative;
    }

    .mat-select.mat-select-empty + .mat-form-field-label-wrapper {
      mat-label {
        color: var(--app-gray-twenty);
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }

  .mat-select {
    font-family: Muli;

    .mat-select-value-text {
      color: var(--site-main-one);
      font-weight: 800;
      font-size: 1.4rem;
    }
  }
}

.mat-select-panel .mat-option {
  // height: auto !important;
  // min-height: 3em !important;
  // line-height: 120% !important;

  .mat-option-text {
    // white-space: normal !important;
    // padding: 5px 0 !important;
  }
}

.form-field-nnki-container {
  position: relative;

  .form-field-error {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    @extend .error-tooltip;
  }
}

.form-field-nnki {
  &.mat-form-field-appearance-legacy {
    padding: 10px 10px;
    background-color: var(--site-theme-main-five);
    @apply rounded;
    line-height: 130%;
    width: 100%;

    &.mat-form-field-invalid {
      border: 1px solid var(--app-red-one) !important;

      .mat-input-element {
        color: var(--app-red-one);
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-label-wrapper {
      top: -1.5em;
      padding-top: 1.5em;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-label {
      font-family: Muli;
    }

    &:not(.mat-form-field-invalid) {
      .mat-form-field-label {
        color: var(--theme-black);
      }
    }

    &:not(.mat-form-field-should-float) {
      label {
        font-size: 1.6rem;
        font-weight: 800;
      }
    }

    &.mat-form-field-should-float {
      .mat-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-appearance-legacy.mat-form-field-can-float {
      transform: translateY(-0.5em) scale(0.75) perspective(100px) translateZ(0.001px);
    }

    .mat-input-element {
      color: var(--site-main-one);
      font-size: 1.6rem;
      font-weight: 800;
      font-family: Muli;
    }

    .mat-form-field-subscript-wrapper {
      font-size: 1rem;
      top: auto;
      bottom: -4px;
    }
  }

  .mat-form-field-infix {
    width: auto;
  }
}

.form-nnki-candidate {
  display: flex;
  flex-direction: column;
  padding: 20px 35px;

  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  &.-candidate.nnki-no-select {
    opacity: 0.6;
  }

  .mat-checkbox-label,
  .mat-radio-label-content {
    user-select: none;
  }

  &.-correction {
    .mat-checkbox-label,
    .mat-radio-label-content {
      user-select: text !important;
    }
  }

  label {
    font-weight: normal !important;
  }

  .daltonien-icon {
    display: none;
  }

  .row-match {
    &.-answerWin .input-nnki {
      color: var(--app-green-three);
      border-color: var(--app-green-three);
    }

    &.-answerFail .input-nnki {
      color: var(--app-red-one);
      border-color: var(--app-red-one);
    }
  }

  .row-answer {
    .mat-radio-button {
      .mat-radio-label-content {
        color: var(--theme-black);
      }
    }
    .mat-checkbox {
      .mat-checkbox-label {
        color: var(--theme-black);
      }
    }
    &.-answerWin {
      .mat-radio-button {
        .mat-radio-outer-circle {
          border-color: var(--app-green-three);
        }
        .mat-radio-inner-circle {
          background: var(--app-green-three);
        }
        .mat-radio-label-content {
          color: var(--app-green-three);
        }
      }
      .mat-checkbox {
        .mat-checkbox-background {
          background: var(--app-green-three);
        }
        .mat-checkbox-label {
          color: var(--app-green-three);
        }
      }
    }
    &.-answerShould {
      .mat-radio-button {
        .mat-radio-outer-circle {
          border-color: var(--app-green-three);
          border-width: 4px;
        }
        &.mat-radio-checked {
          .mat-radio-inner-circle {
            background: var(--app-green-three);
          }
        }
        .mat-radio-label-content {
          color: var(--app-green-three);
        }
      }

      .mat-checkbox {
        .mat-checkbox-frame {
          border-color: var(--app-green-three);
          border-width: 3px;
        }
        .mat-checkbox-label {
          color: var(--app-green-three);
        }
      }
    }
    &.-answerShouldNot {
      .mat-radio-button {
        .mat-radio-outer-circle {
          border-color: var(--app-red-one);
        }
        .mat-radio-inner-circle {
          background: var(--app-red-one);
        }
        .mat-radio-label-content {
          color: var(--app-red-one);
        }
      }
      .mat-checkbox {
        .mat-checkbox-background {
          background: var(--app-red-one);
        }
        .mat-checkbox-label {
          color: var(--app-red-one);
        }
      }
    }
  }

  &.-daltonien {
    @media (max-width: $screen-xs-max) {
      padding-left: 20px;
    }
    .row-match,
    .row-answer {
      position: relative;

      .daltonien-icon {
        position: absolute;
        left: -25px;
      }

      &.-answerWin,
      &.-answerShould {
        .daltonien-icon.-check {
          display: block;
          color: var(--app-green-three);
          top: 5px;
        }
      }

      &.-answerFail,
      &.-answerShouldNot {
        .daltonien-icon.-times {
          display: block;
          color: var(--app-red-one);
          top: 3px;
        }
      }
    }

    .row-match {
      &.-answerWin,
      &.-answerShould {
        .daltonien-icon.-check {
          top: 5px;
        }
      }

      &.-answerFail,
      &.-answerShouldNot,
      &.-answerWin,
      &.-answerShould {
        .daltonien-icon.-times,
        .daltonien-icon.-check {
          top: 22px;
        }
      }
    }
  }
}

.mat-datepicker-toggle {
  svg-icon {
    color: var(--theme-gray-twentyseven);
  }
}

.mat-slide-toggle {
  width: 100%;
  min-height: 24px;
  height: auto !important;

  &.mat-slide-toggle-label-before .mat-slide-toggle-bar {
    margin-left: auto;
  }
}

.light-theme-mode,
.dark-theme-mode {
  .mat-button-toggle-group {
    &.mat-button-toggle-group-appearance-standard {
      border: 0;
      .mat-button-toggle {
        &:focus {
          outline: 0;
        }

        &.mat-button-toggle-appearance-standard {
          border: 1px solid var(--theme-gray-fifteen);
          font-family: Muli;
          font-weight: bold;
          border-left-width: 0;

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left-width: 1px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          &.mat-button-toggle-checked {
            background: var(--theme-white);
            color: var(--site-main-one);
            border-color: var(--site-main-one);
            border-left-width: 1px;
          }

          .mat-button-toggle-label-content {
            font-size: 1.4rem;
            line-height: 38px;
          }
        }
      }

      &.-longText {
        .mat-button-toggle {
          &.mat-button-toggle-appearance-standard {
            white-space: normal;
          }
        }
      }

      &.-textWrap {
        @media (max-width: $screen-xs-max) {
          flex-wrap: wrap;
        }

        .mat-button-toggle {
          &.mat-button-toggle-appearance-standard {
            display: flex;
            align-items: center;
            max-width: 190px;
            white-space: normal;

            @media (max-width: $screen-xs-max) {
              max-width: none;
              width: 100%;
              margin-bottom: 10px;
              border-left-width: 1px;
              border-radius: 4px;
            }

            .mat-button-toggle-button {
              padding: 10px 0;

              .mat-button-toggle-label-content {
                line-height: 150%;
              }
            }
          }
        }
      }
    }
  }
}

// formulaires consensus
.form-nnki-light {
  .form-field-nnki.mat-form-field-appearance-legacy {
    @apply bg-theme-white border border-theme-gray-fifteen;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-bottom: 4px;
  }
}
