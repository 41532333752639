.free-gradient {
  @apply text-white;
  background: linear-gradient(
    119.3deg,
    var(--app-free-gradient-1) 2.27%,
    var(--app-free-gradient-2) 86.31%
  );
}

.premiumplus-gradient {
  @apply text-white;
  background: linear-gradient(
    155.69deg,
    var(--app-premiumplus-gradient-1) 1.59%,
    var(--app-premiumplus-gradient-2) 71.85%
  );
}

.elite-gradient {
  @apply text-gold;
  background: linear-gradient(
    155.69deg,
    var(--app-elite-gradient-1) 1.59%,
    var(--app-elite-gradient-2) 71.85%
  );
}

.btn-gradient {
  background-image: linear-gradient(
    24deg,
    var(--site-card-gradient-1) 0%,
    var(--site-card-gradient-2) 100%
  );
}
