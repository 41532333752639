.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @apply z-topNav;
  padding-top: var(--safe-area-inset-top);
}

.navbar-scrolltop {
  flex-grow: 1;
  align-self: stretch;
  cursor: pointer;
}

.navbar-hamburger {
  cursor: pointer;
  color: var(--site-main-one);

  // MOBILE DEVICE DEPENDANT
  @media (min-width: $screen-md) {
    display: none;
  }
}

.navbar-search {
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 540px;

  border-radius: 16px;
  border: 1px solid var(--theme-gray-seven);
  box-shadow: 0px 4px 10px rgba(46, 48, 64, 0.2);

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    position: absolute;
    left: 0;
    width: 100%;

    z-index: calc(#{theme('zIndex.topNav')} - 1);
    background: var(--theme-white);
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    @apply transition-spacing top-navbarSmallHeight;

    border-radius: 0;

    box-shadow: none;
    border: 0;

    &.-active {
      height: calc(var(--safe-area-inset-top) + 60px);
      padding-top: calc(var(--safe-area-inset-top) + 20px);
      padding-bottom: 20px;
    }
  }

  .form-search {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 2px 10px 20px;
    border-radius: 16px;

    // MOBILE DEVICE DEPENDANT
    @media (max-width: $screen-sm-max) {
      width: 100%;
      border-radius: 40px;
    }

    &.-searchEntered {
      background: var(--site-main-six);

      .search-submit {
        display: block;
      }
    }

    .search-icon {
      border: 0;
      margin-right: 15px;
      color: var(--site-main-one);
    }

    .form-input {
      background: transparent;
      border: 0;
      font-family: Muli;
      font-size: 1.4rem;
      width: 100%;
      margin-right: 0;

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: var(--theme-gray-twentysix);
      }

      &:-moz-placeholder,
      &::-moz-placeholder {
        color: 9ea0a5;
        opacity: 0.7;
      }

      // MOBILE DEVICE DEPENDANT
      @media (max-width: $screen-sm-max) {
        color: var(--theme-black);
        margin-right: 0;
        width: 100%;

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: var(--theme-gray-twentyfour);
        }

        &:-moz-placeholder,
        &::-moz-placeholder {
          color: var(--theme-black);
          opacity: 0.7;
        }
      }
    }

    .search-submit {
      position: absolute;
      right: 15px;
      top: calc(50% - 6px);
      display: none;
    }
  }
}

.module-badge {
  white-space: nowrap;
  overflow: hidden;

  color: white;
  font-size: 9px;
  line-height: 17px;
  text-align: center;

  width: 16px;
  height: 16px;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    line-height: 20px;
    width: 19px;
    height: 19px;
    font-size: 10px;
  }

  .background {
    background: var(--site-main-one);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: heartbeat 3s ease-in-out infinite both;
    animation: heartbeat 3s ease-in-out infinite both;
  }

  .content {
    position: relative;

    &.big-number {
      font-size: 7px;
      top: -1px;
    }
  }
}

.mat-sidenav-fixed {
  padding-top: var(--safe-area-inset-top) !important;
  padding-bottom: var(--safe-area-inset-bottom) !important;
  overflow: visible !important;

  .mat-drawer-inner-container {
    overflow: visible !important;
  }
}

.navbar-user {
  z-index: calc(#{theme('zIndex.topNav')} - 1);
  position: relative;
  margin-left: 20px;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    display: none;
  }

  .user-menu-toggle {
    cursor: pointer;
    max-width: 180px;
    display: flex;
    align-items: center;
    @apply space-x-8;
  }

  .user-menu-left {
    @apply leading-snug overflow-hidden;
  }

  .user-username {
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-sub {
    @apply text-site-main-one text-12 font-extrabold;
  }

  .nnki-rounded-background-image {
    margin-left: 15px;
    width: 40px;
    flex-shrink: 0;
  }

  .user-menu {
    position: absolute;
    top: 48px;
    right: 0;

    border-radius: 16px;
    box-shadow: 0 4px 10px 0 var(--theme-blue-three);
    background-color: var(--theme-white);

    .user-menu-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-size: 1.4rem;
      font-weight: 600;
      white-space: nowrap;

      padding: 15px;

      cursor: pointer;

      svg-icon {
        margin-right: 15px;
        color: var(--app-gray-twentynine);
      }

      &.-active,
      &:hover {
        color: var(--site-main-one);

        svg-icon {
          color: var(--site-main-one);
        }
      }
    }
  }
}
