.block-nnki-page-title {
  font-size: 2.6rem;
  font-weight: 800;
  padding-right: 20px;

  margin-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    display: none;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.-withBackButton {
    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      > .title-text {
        display: none;
      }
    }

    .back-button {
      margin-right: 60px;
      cursor: pointer;

      @media (max-width: $screen-md-max) {
        margin-right: 20px;
      }
    }

    .back-logo {
      margin-right: 76px;
      cursor: pointer;

      @media (max-width: $screen-md-max) {
        margin-right: 36px;
      }

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }

    .title-search {
      margin-left: auto;
      font-weight: normal;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }

  &.-withButtons {
    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      > .title-text {
        display: none;
      }
    }
  }

  &.-showMobile {
    @media (max-width: $screen-xs-max) {
      display: flex;
      margin-bottom: 20px;

      > .title-text {
        display: block;
      }
    }
  }

  &.-fixedWidget {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @apply z-widgets;
    background: var(--theme-white);

    padding: var(--safe-area-inset-top) 20px 0 20px;
    height: calc(var(--safe-area-inset-top) + 69px);
    margin: 0;

    &.-aboveTabs {
      background: transparent;
      left: auto;
      height: calc(var(--safe-area-inset-top) + 63px);

      @media (max-width: $screen-xs-max) {
        height: calc(var(--safe-area-inset-top) + 63px);
        padding-left: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      height: calc(var(--safe-area-inset-top) + 50px);
    }
  }

  .title-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2px 0;
  }
}

.block-nnki-title {
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 2.3rem;
  color: var(--theme-black);
  font-weight: 600;

  min-height: 36px;

  @media (max-width: $screen-xs-max) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .goToContent {
    margin-left: 5px;
    padding: 5px;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

.block-nnki-smalltitle {
  color: var(--site-main-one);
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 15px;
}

.grid-container,
.swiper-slide {
  .card {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .card-content {
      flex-grow: 1;
    }
  }
}

.item-image-grid-container {
  @apply grid gap-12 grid-cols-[repeat(auto-fill,minmax(250px,1fr))];
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(349px, 1fr));
  grid-gap: 30px;

  &.-students-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  &.-video-grid {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }

  @media (max-width: 400px) {
    @apply grid-cols-1;
  }
}

.block-content-insession {
  padding-left: 120px;
  padding-right: 60px;

  @media (max-width: $screen-md-max) {
    padding-left: 80px;
    padding-right: 40px;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-content {
  background-image: linear-gradient(
    to top,
    var(--site-section-4-gradient-1) 50%,
    var(--site-section-4-gradient-2) 100%
  );

  padding-top: 15px;
  padding-bottom: 130px;

  color: var(--app-white);

  @media (max-width: $screen-xs-max) {
    padding-bottom: 45px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $screen-xs-max) {
      align-items: flex-start;
    }

    &.-withBlockOnRight {
      padding-right: 267px;

      @media (max-width: $screen-xs-max) {
        padding-right: 0;
      }
    }
  }

  .header-picto {
    background: var(--theme-gray-three);
    margin-right: 10px;
    border-radius: 50%;

    width: 61px;
    height: 61px;
    flex-shrink: 0;

    border: 3px solid var(--theme-white);
    overflow: hidden;

    &.-random,
    &.-transversal {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 800;
      line-height: 100%;
      color: var(--theme-white);
    }

    &.-random {
      background: var(--app-purple-one);
    }

    &.-transversal {
      background: var(--app-orange-three);
    }
  }

  .header-text {
    margin-right: 20px;
    overflow: hidden;

    @media (max-width: $screen-xs-max) {
      margin-right: 0;
    }
  }

  .header-title {
    font-size: 1.4rem;
    font-weight: 600;
    padding-bottom: 1px;
    margin-bottom: 5px;

    > p {
      @apply truncate;

      @media (max-width: $screen-xs-max) {
        white-space: normal;
      }
    }
  }

  .header-theme {
    font-size: 1.6rem;
    font-weight: 800;
    padding-bottom: 1px;

    > p {
      @apply truncate;

      &.-noClamp {
        @apply whitespace-normal;
      }

      @media (max-width: $screen-xs-max) {
        @apply whitespace-normal line-clamp-3;

        &.-noClamp {
          @apply line-clamp-none;
        }
      }
    }
  }

  .header-details {
    margin-top: 10px;
    display: flex;
    align-items: stretch;

    .header-detail {
      color: var(--theme-black);
      font-size: 1.2rem;
      font-weight: bold;
      background: var(--theme-white);
      padding: 0 8px;
      height: 24px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      margin-right: 10px;
      margin-top: 2px;
      margin-bottom: 2px;

      &:last-child {
        margin-right: 0;
      }

      svg-icon {
        margin-right: 4px;
        color: var(--site-main-one);
      }
    }
  }

  .header-nav {
    display: flex;
    margin-left: auto;

    a {
      color: var(--theme-white);
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.below-header {
  margin-top: -115px;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: $screen-xs-max) {
    margin-top: -30px;

    &.-noHorizontalPaddingMobile {
      padding-left: 0;
      padding-right: 0;
    }

    &.-withInsideTabs {
      margin-top: -50px;
    }
  }
}

.block--categories {
  @apply flex flex-wrap gap-x-4 gap-y-2;
}

.badge-category {
  border-radius: 12px;
  background-color: var(--site-theme-main-five);
  color: var(--theme-gray-one);

  font-size: 1.2rem;
  padding: 7px;

  &.-truncated {
    max-width: 150px;
    @apply truncate;
  }

  &.-white {
    @apply shadow;
    background-color: var(--theme-white);
    padding-left: 12px;
    padding-right: 12px;
  }

  &.-plus {
    background: linear-gradient(
      144.11deg,
      var(--site-card-gradient-1) 0%,
      var(--site-card-gradient-2) 100%
    );
    color: var(--app-white);
  }

  &.-active {
    background: var(--site-light-card);
    color: var(--theme-white);
  }
}

.block--training-svg {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  > * {
    overflow: hidden;
  }

  svg-icon {
    color: var(--site-main-one);
    margin-right: 10px;
  }

  .training-label {
    font-size: 1.4rem;
    font-weight: bold;

    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      font-size: 1.3rem;
      white-space: nowrap;
    }
  }

  .training-value {
    display: flex;
    align-items: center;
    margin-top: 7px;
  }

  .value-number {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--site-main-one);
    white-space: nowrap;
    margin-right: 5px;
  }

  .ecni_progress_bar {
    width: auto;
    flex-grow: 1;
    height: 5px;
    max-width: 120px;
  }
}

.block--rate {
  position: absolute;
  top: 20px;
  right: 0;

  .rate-panel {
    background: var(--theme-white);
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 25px;
    position: relative;
    @apply z-2;
    white-space: nowrap;

    flex-direction: column;
    display: flex;
    align-items: flex-end;
  }

  .mask {
    position: fixed;
    @apply z-1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.block-scrollable-list {
  @apply h-[calc(100vh-380px)] sm:h-[270px] min-h-[270px] overflow-auto border border-theme-gray-fifteen p-4 rounded;

  .list-grid {
    @apply grid grid-cols-[repeat(auto-fill,minmax(80px,1fr))] gap-4;
  }
}

.block-videos-list {
  @apply grid grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(500px,1fr))] gap-8;
}

.block-iframe {
  @apply aspect-video relative;
  iframe {
    @apply w-full h-full;
  }
}

.block--pricing-cards {
  @apply flex space-x-4;

  img {
    @apply w-[65px];
  }
}

.block--stripe-container {
  @apply p-4 bg-site-main-five w-full h-[60px] flex flex-col justify-center border border-transparent rounded;

  &.StripeElement--invalid {
    @apply border-red-one;
  }
}

.block--friends-list-search {
  .form-field-nnki.mat-form-field-appearance-legacy {
    @apply pt-2 pb-0;
  }
  .form-field-nnki.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) label {
    @apply text-14 opacity-40;
  }
  .btn-nnki {
    @apply h-auto w-[47px] shrink-0;
  }
}

.block--modal-challenge {
  .discipline-badge {
    @apply text-20 font-extrabold w-full flex justify-center overflow-hidden;

    transition: transform 0.4s, opacity 0.4s;
    transform: translateY(-200px);

    &.-inside {
      transform: translateY(0);
    }

    &.-gone {
      transform: translateY(0);
      @apply opacity-0;
    }

    .badge-content {
      @apply rounded-[40px] bg-theme-white py-3 md:py-5 px-20 md:px-28 flex items-center max-w-[calc(100%-40px)] md:max-w-[420px];

      > img {
        @apply mr-4 md:mr-6 rounded-full overflow-hidden w-[48px] h-[48px] md:w-[54px] md:h-[54px];
      }

      > span {
        @apply grow basis-0 truncate;
      }
    }
  }

  .question-number {
    @apply rounded-[22px] border border-theme-white py-4 px-16 mb-12 text-20 font-extrabold uppercase text-theme-white opacity-0;

    transition: opacity 0.4s;

    &.-inside {
      @apply opacity-100;
    }
  }

  .top-question-challenge {
    @apply bg-theme-white shadow sm:h-36 flex flex-wrap sm:flex-nowrap items-center justify-between p-8 pt-4 sm:py-0 sm:px-28 fixed sm:static top-safeTop left-0 right-0 z-1;

    transition: transform 0.4s;
    transform: translateY(-200px);

    &.-inside {
      transform: translateY(0);
    }

    .discipline-badge {
      @apply w-auto mb-4 sm:mb-0 mr-8 pr-8 sm:pr-0 grow basis-0 block;

      .badge-content {
        @apply p-0 max-w-full;
      }
    }

    app-widget-time-remaining {
      @apply w-full sm:w-[300px] min-w-0 shrink-0 grow-0;
    }
  }

  .content-question-challenge {
    background-image: linear-gradient(
      129deg,
      var(--site-card-gradient-1) 28%,
      var(--site-card-gradient-2) 91%
    );

    padding: 30px 20px 85px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      padding-top: 138px;
      padding-left: 10px;
      padding-right: 10px;
    }

    transition: opacity 0.4s;
    opacity: 0;

    &.-inside {
      opacity: 1;
    }

    > * {
      max-width: 100%;
    }

    .question-number {
      @apply text-14;
    }

    .question-content {
      width: 800px;
      margin: 0 auto 40px auto;

      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        flex-wrap: wrap;
      }

      transition: opacity 0.4s;
      opacity: 0;

      &.-inside {
        opacity: 1;
      }

      app-question-images {
        margin-left: 0;
        margin-right: 20px;

        @media (max-width: $screen-xs-max) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;

          order: 0;
        }
      }

      .question-text {
        flex-grow: 1;

        color: var(--theme-white);
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 130%;
        text-align: center;

        @media (max-width: $screen-xs-max) {
          font-size: 1.8rem;
        }

        p {
          strong {
            color: inherit;
          }
        }
      }
    }

    .question-answers {
      margin-bottom: 30px;

      transition: opacity 0.4s;
      opacity: 0;

      display: flex;
      flex-direction: column;
      align-items: stretch;

      &.-inside {
        opacity: 1;
      }

      .question-answer {
        background: var(--theme-white);
        color: var(--site-main-one);

        @apply rounded;
        width: 550px;
        max-width: 100%;
        margin: 0 auto 10px auto;

        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;
        line-height: 130%;
        padding: 10px 20px;

        border: 3px solid transparent;

        cursor: pointer;

        transition: all 0.4s;

        &.-incorrect {
          opacity: 0.2;
          color: var(--app-green-white);
        }

        &.-correct {
          border-color: var(--app-green-three);
          color: var(--app-green-three);
        }

        &.-checked {
          background: var(--site-main-one);
          color: var(--app-white);

          &.-incorrect {
            background: var(--app-red-one);
            color: var(--app-white);
          }

          &.-correct {
            border-color: transparent;
            background: var(--app-green-three);
            color: var(--app-white);
          }
        }
      }
    }

    .question-qroc {
      width: 550px;
      margin-bottom: 30px;

      transition: opacity 0.4s;
      opacity: 0;

      &.-inside {
        opacity: 1;
      }

      .form-nnki {
        padding: 0;

        &.nnki-no-select {
          opacity: 1;

          .input-nnki {
            border: 3px solid transparent;
            transition: all 0.4s;
          }

          .-incorrect {
            background: var(--app-red-one);
            color: var(--theme-white);

            opacity: 0.2;
          }

          .-correct {
            background: var(--app-green-three);
            color: var(--theme-white);
            font-weight: 800;
          }

          .-accepted {
            border-color: var(--app-green-three);
            background: var(--theme-white);
            color: var(--app-green-three);
            font-weight: 800;
          }
        }
      }
    }
  }

  .question-button {
    transition: opacity 0.4s;
    opacity: 0;

    margin-top: 20px;

    @media (max-width: $screen-xs-max) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 10px calc(10px + var(--safe-area-inset-bottom)) 10px;
      margin-top: 0;
      background: var(--theme-white);
    }

    &.-inside {
      opacity: 1;
    }

    .btn-nnki {
      width: 300px;
      max-width: 100%;

      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }
}

.block--pricing-offer {
  @apply h-full bg-cover bg-center bg-no-repeat p-8 text-white font-semibold text-center relative sm:rounded flex flex-col justify-between items-center space-y-4;

  &.-decouverte {
    background-image: url(/assets/images/backgrounds/pricings/pricing_decouverte.svg);
  }

  &.-premium {
    background-image: url(/assets/images/backgrounds/pricings/pricing_premium.svg);
  }

  &.-premiumplus {
    background-image: url(/assets/images/backgrounds/pricings/pricing_premiumplus.svg);
  }

  &.-subscription {
    @apply hidden sm:flex;
  }

  .pricing-offer__title {
    @apply text-30 sm:text-20 md:text-30;
  }

  .pricing-offer__subtitle {
    @apply font-avenir text-18 font-extrabold;
  }

  .pricing-offer__separator {
    @apply flex justify-center;
  }

  .pricing-offer__price {
    @apply text-30;
  }

  .pricing-offer__monthes {
    @apply text-18 space-y-2;
  }

  .pricing-offer__infos {
    @apply font-avenir text-14;
  }

  .pricing-offer__buttons {
    @apply hidden sm:block min-h-[40px];

    .btn-nnki {
      svg-icon {
        @apply ml-4;
      }
    }
  }
}

.classic-grid {
  @apply grid grid-cols-1 gap-4 content-start items-start;
}

.styled-text {
  > * {
    @apply mb-4;
  }
  ul {
    li {
      @apply list-disc ml-8;
    }
  }
}
