section {
  width: 100%;
  @apply z-0;
  position: relative;
}

.content-component.-inSession {
  .logged-area-container {
    padding-top: 0px;
  }
}

.fixed-inSession-logo {
  position: fixed;
  @apply z-widgets;
  top: calc(14px + var(--safe-area-inset-top));
  left: 20px;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.fixed-inSession-search {
  position: fixed;
  @apply z-widgets;
  top: calc(11px + var(--safe-area-inset-top));
  right: 20px;

  // MOBILE DEVICE DEPENDANT
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

.content-with-fixed-widgets {
  padding-top: 69px;

  @media (max-width: $screen-xs-max) {
    padding-top: 50px;
  }
}
