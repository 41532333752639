.zap_canvas_block {
  display: flex;
}

.zap_canvas_container {
  position: relative;
}

.zap_canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.zap_image {
  max-width: 100%;
  position: relative;
}
