.mat-accordion {
  app-writer-area {
    .mat-expansion-panel {
      @apply text-theme-black;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      @apply shadow-none border border-site-theme-main-two;
    }

    .mat-expansion-panel-header {
      @apply font-default;
      .mat-content {
        @apply font-bold text-site-main-one;
      }
    }

    .mat-expansion-panel-content {
      @apply font-default;
    }

    .mat-content {
      > * {
        @apply truncate;
      }
    }
  }

  .nnki-accordion-header {
    @apply text-site-main-one bg-site-theme-main-five hover:bg-site-theme-main-five;

    .nnki-accordion-title {
      @apply font-bold flex justify-between items-center w-full;
    }

    .mat-expansion-indicator::after {
      @apply text-site-main-one;
    }

    & + .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @apply pt-8;
      }
    }

    &.questions-accordions + .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @apply p-0 bg-site-theme-main-four;
      }
    }
  }

  .expansion-questions-list {
    @apply max-h-[200px] overflow-auto p-[24px] relative;

    .questions {
      @apply relative;
    }

    .question {
      @apply flex items-center justify-between py-[6px] px-0 text-13 font-bold cursor-pointer;

      > svg-icon {
        @apply text-blue-five;
      }
    }
  }
}
