.section--public {
  @apply min-h-screen overflow-hidden relative pt-publicNavbarHeight;

  @media (max-width: $screen-sm-max) {
    @apply pt-publicNavbarSmallHeight;
  }

  &.background--wave {
    &:before {
      @apply left-[380px] md:left-[calc(50vw-512px+380px)] right-auto;
    }
  }
}

.background--wave {
  &:before {
    content: '';

    @apply hidden sm:block absolute top-0;

    height: 836px;
    width: 1350px;
    transform: scaleX(-1) rotate(-45deg);
    border-radius: 242px;
    background: linear-gradient(
      341.57deg,
      var(--site-public-gradient-1) 0%,
      var(--site-public-gradient-2) 100%
    );
  }
}

.background--public {
  &:after {
    content: '';

    @apply block absolute pointer-events-none z-0;

    top: -60px;
    left: 200px;

    height: calc(100vh + 120px);
    width: 324px;

    background-image: url(/assets/images/illustrations/login-new.svg);
    background-position: top 250px right;
    background-repeat: no-repeat;
    background-size: 324px;

    @screen sm {
      left: 380px;
      top: -120px;
      width: 500px;
      background-size: 500px;
    }

    @screen md {
      left: calc(50vw - 512px + 380px);
    }
  }
}
