.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: Muli;

  .mat-horizontal-component-container {
    @media (max-width: $screen-xs-max) {
      padding: 0 10px 10px 10px;
    }
  }

  .mat-step-icon {
    @media (min-width: $screen-sm) {
      height: 30px;
      width: 30px;
    }
  }

  .mat-step-label {
    min-width: 0;
  }
}
