.ecni_progress_bar {
  height: 10px;
  width: 150px;
  background: var(--theme-gray-sixteen);
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &.-small {
    height: 5px;
  }

  .progress_bar_in {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;

    background-repeat: repeat-x;

    @apply transition-width;

    &.-main {
      background-color: var(--site-main-one);
    }

    &.-green {
      background-color: var(--app-green-three);
    }

    &.-green-three {
      background-color: var(--app-green-three);
    }

    &.-red {
      background-color: var(--app-red-one);
    }

    &.-purple {
      background-color: var(--app-purple-one);
    }

    &.-blue {
      background-color: var(--app-blue-one);
    }
  }
}
