.widgets {
  display: flex;
  align-items: center;
  justify-content: start;
  @apply space-x-8;

  flex-grow: 1;
}

app-widget-time-remaining,
.widget-time-remaining {
  display: flex;
  align-items: center;
  max-width: 600px;
  flex-grow: 1;

  @media (max-width: $screen-xs-max) {
    flex-wrap: wrap;
  }

  > * {
    margin-left: 10px;
    margin-right: 10px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.-disabled {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

    .text,
    .ecni_progress_bar {
      opacity: 0.5;
    }
  }

  .text {
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width: $screen-xs-max) {
      font-size: 1.1rem;
      text-align: center;
    }

    &.-finished {
      color: var(--app-orange-one);
    }
  }

  .ecni_progress_bar {
    height: 5px;
    width: auto;
    flex-grow: 1;

    @media (max-width: $screen-xs-max) {
      margin-top: 5px;
      width: 100%;
    }
  }
}

.widget-buttons {
  display: flex;
  align-items: center;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }

  .widget-button {
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    color: var(--theme-black);

    &.-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.-greyed {
      > svg-icon {
        opacity: 0.5;
      }
    }
  }

  &.-onBottomMobile {
    @media (max-width: $screen-xs-max) {
      position: fixed;
      @apply z-tabs;
      bottom: calc(20px + var(--safe-area-inset-bottom));
      right: 20px;

      .widget-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--site-main-one);
        border-radius: 50%;
        width: 70px;
        height: 70px;
        padding: 0;
        @apply shadow;
        margin: 0;
        color: var(--theme-white);

        > svg-icon {
          opacity: 1;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.widget-progressions {
  display: flex;
  align-items: stretch;
  margin-left: 20px;

  @media (max-width: $screen-xs-max) {
    margin-left: 0;
    display: none;
  }

  &.-mobile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media (min-width: $screen-sm) {
      display: none;
    }

    app-widget-progression {
      @apply rounded;
      @apply shadow;
      padding: 15px 13px;
      margin-bottom: 10px;
      flex-grow: 1;

      &:nth-child(1) {
        background: var(--site-main-one);
      }

      &:nth-child(2) {
        background: var(--app-green-one);
      }

      &:first-child {
        margin-left: 10px;
      }
      &:last-child {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    .text-container {
      color: var(--theme-white);
    }
  }

  app-widget-progression {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .widget-progression {
    display: flex;
    align-items: center;

    .text-container {
      white-space: nowrap;

      @media (max-width: $screen-xs-max) {
        line-height: 120%;
      }
      .label {
        font-size: 1.3rem;
        font-weight: 600;

        @media (max-width: $screen-xs-max) {
          font-size: 1rem;
        }

        svg-icon {
          margin-left: 5px;
        }
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 1.7rem;
        font-weight: 800;

        @media (max-width: $screen-xs-max) {
          font-size: 1.4rem;
        }

        small {
          font-size: 1.2rem;
          font-weight: 600;
        }

        > span {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
