@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir/AvenirLTStdBook.woff2') format('woff2'),
    url('/assets/fonts/Avenir/AvenirLTStdBook.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir/AvenirLTStdRoman.woff2') format('woff2'),
    url('/assets/fonts/Avenir/AvenirLTStdRoman.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir/AvenirLTStdHeavy.woff2') format('woff2'),
    url('/assets/fonts/Avenir/AvenirLTStdHeavy.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir/AvenirLTStdBlack.woff2') format('woff2'),
    url('/assets/fonts/Avenir/AvenirLTStdBlack.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-light.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-regular.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-semibold.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-bold.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-extrabold.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli/muli-black.woff2') format('woff2'),
    url('/assets/fonts/Muli/muli-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-semibold.woff2') format('woff2'),
    url('/assets/fonts/montserrat/montserrat-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('/assets/fonts/montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-extrabold.woff2') format('woff2'),
    url('/assets/fonts/montserrat/montserrat-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
