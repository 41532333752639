@import 'abstracts/variables';

.iti__search-input {
  height: 40px;
  padding: 5px;
}

.iti__selected-dial-code {
  font-weight: bold;
  color: var(--site-main-one);
}
