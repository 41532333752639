html,
body {
  @apply h-full m-0 bg-theme-white text-theme-black;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply text-14 font-default pt-safeTop pb-safeBottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
label {
  @apply m-0 p-0;
}

p {
  @apply leading-snug;
}

ul {
  @apply list-none p-0 m-0;
}

a,
div,
input,
textarea,
button {
  &:focus,
  &:active {
    outline: none !important;
  }
}

a {
  @apply cursor-pointer transition-colors;
  color: inherit;
  text-decoration: inherit;
}

img {
  @apply max-w-full align-middle;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

sup {
  font-size: 60%;
}

* {
  @apply box-border;
}

.innerHtml {
  p {
    @apply mb-4;
    strong {
      @apply text-site-main-one;
    }
  }

  ul {
    @apply list-disc ml-8 mb-8 space-y-4;
  }

  ol {
    @apply list-decimal ml-8 mb-8 space-y-4;
  }
}
