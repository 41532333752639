.admin-list.cdk-drop-list {
  &.cdk-drop-list-dragging {
    .cdk-drag:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .admin-list-element {
    @apply cursor-move;
  }
}

.cdk-drag-preview {
  @apply box-border shadow border border-site-theme-main-two bg-theme-white;

  > * {
    @apply invisible;
  }
}

.cdk-drag-placeholder {
  @apply opacity-0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
