.screen_height {
  height: calc(100vh - 175px);
  overflow: auto;

  // MOBILE DEVICE DEPENDANT
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    height: calc(100vh - 215px);
  }

  @media (max-width: $screen-xs-max) {
    height: calc(100vh - 195px);
  }
}

.card--splitted {
  display: flex;
  align-items: stretch;
  background: var(--theme-white);

  margin: 0;

  overflow: hidden;

  @apply shadow;

  min-height: 58px;

  @media (max-width: $screen-xs-max) {
    background: transparent;
    box-shadow: none;
    border: 0;
    align-items: flex-start;
  }

  &.-inDiscussion {
    .card_left {
      @media (max-width: $screen-xs-max) {
        transform: translateX(-100%);
      }
    }

    .card_right {
      @media (max-width: $screen-xs-max) {
        transform: translateX(0);
      }
    }
  }

  .card_left {
    position: relative;
    width: 300px;
    flex-shrink: 0;
    border-right: 1px solid var(--theme-gray-eight);

    background: var(--theme-white);

    @extend .screen_height;

    @media (max-width: $screen-md-max) {
      width: 240px;
    }

    @media (max-width: $screen-xs-max) {
      border-right: 0;
    }
  }

  .card_right {
    flex-grow: 1;
    display: flex;

    @extend .screen_height;

    @media (max-width: $screen-xs-max) {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(100vw);
      height: 100vh;
      z-index: calc(#{theme('zIndex.tabs')} + 1);
      background: var(--theme-white);

      padding-top: var(--safe-area-inset-top);
      padding-bottom: var(--safe-area-inset-top);
    }

    > div,
    > form {
      flex-shrink: 0;
    }
  }

  .card_left,
  .card_right {
    @media (max-width: $screen-xs-max) {
      width: 100vw;
      flex-shrink: 0;
      @apply transition-transform;
    }
  }
}

.card--splitted,
.feedbacks-list-modal {
  .feedback-preview {
    border-bottom: 1px solid var(--theme-gray-eight);
    background: var(--site-theme-main-five);
    padding: 15px 20px 10px 20px;

    position: relative;

    cursor: pointer;

    border-right: 5px solid transparent;

    @media (min-width: $screen-sm) {
      min-height: 86px;
    }

    &.-opened {
      background: var(--theme-white);
      border-right-color: var(--site-main-one);
    }

    .feedback-preview-top {
      display: flex;
      align-items: stretch;
      overflow: hidden;
      margin-bottom: 10px;
      min-height: 37px;

      > .nnki-rounded-background-image,
      > svg-icon {
        flex-shrink: 0;
        margin-right: 20px;
      }

      > .nnki-rounded-background-image {
        width: 37px;
      }

      > svg-icon {
        color: var(--site-main-one);
      }
    }

    .feedback-preview-infos {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;

      .author {
        @apply line-clamp-2;
        padding-bottom: 1px;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 140%;

        &.-withNew {
          padding-right: 20px;
        }
      }
      .hour {
        flex-shrink: 0;
        margin-left: 10px;
        font-size: 1.2rem;
        color: var(--app-gray-twentynine);
        margin-bottom: auto;
      }
    }

    .feedback-preview-text {
      font-size: 1.2rem;
      @apply truncate;
      padding-bottom: 1px;
    }

    .feedback-notif {
      position: absolute;
      top: 35px;
      right: 15px;
      background: var(--app-green-three);
      border-radius: 2px;
      color: var(--app-white);
      font-size: 1rem;
      font-weight: 800;
      padding: 4px 7px;
    }
  }

  .feedback_preview-content {
    margin-left: 15px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 0;

    .content_author-hour {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      margin-bottom: 3px;

      .author {
        color: var(--site-main-one);
        font-weight: bold;
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
      }

      .hour {
        font-size: 1.3rem;
        font-family: Avenir;
        white-space: nowrap;
        margin-left: 5px;

        @media (max-width: $screen-xs-max) {
          margin-right: -20px;
        }
      }
    }
  }

  .feedback_header {
    display: flex;
    align-items: center;

    padding: 20px 25px;
    flex-shrink: 0;

    border-bottom: 1px solid var(--theme-gray-eight);

    min-height: 58px;

    @media (max-width: $screen-xs-max) {
      padding: 10px 15px 10px 15px;
      background: var(--theme-white);

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @apply z-1;
    }

    .back-to-list {
      color: var(--site-main-one);
      margin-right: 20px;
      cursor: pointer;
    }

    .users-pictures-list {
      cursor: pointer;
    }

    .user-message {
      display: flex;
      align-items: center;
      overflow: hidden;

      .nnki-rounded-background-image {
        width: 37px;
        margin-right: 10px;
        flex-shrink: 0;
      }

      .username {
        font-weight: 600;
        @apply truncate;
      }
    }

    .open-question {
      margin-left: auto;
    }
  }

  .feedback_discussion {
    margin-top: auto;
    overflow-y: auto;

    @media (max-width: $screen-xs-max) {
      padding-top: 58px;
      padding-bottom: 71px;
    }

    .discussion_answer {
      max-width: 70%;
      padding: 0 20px 10px 20px;

      @media (max-width: $screen-xs-max) {
        max-width: 90%;
      }

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 30px;
      }

      .answer_block {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .nnki-rounded-background-image {
          width: 32px;
          flex-shrink: 0;
          margin-right: 10px;
        }

        .answer_content {
          @apply rounded;
          background: var(--theme-gray-eighteen);
          font-size: 1.4rem;
          padding: 10px 15px;
        }
      }

      .answer_meta {
        padding-left: 38px;
        font-size: 1.2rem;
        color: var(--app-gray-twentynine);
      }

      &.-fromUser {
        margin-left: auto;

        .nnki-rounded-background-image {
          margin-right: 0;
          margin-left: 10px;
          order: 2;
        }

        .answer_block {
          .answer_content {
            @apply rounded;
            background: var(--site-theme-main-two);
            margin-left: auto;
          }
        }

        .answer_meta {
          text-align: right;
          padding-left: 0px;
          padding-right: 42px;
        }
      }
    }
  }

  .form-nnki {
    border-top: 1px solid var(--theme-gray-eight);
    padding: 15px 20px 5px 20px;
    flex-shrink: 0;

    @media (max-width: $screen-xs-max) {
      background: var(--theme-white);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .row-form-nnki.-wrapButton {
      padding-right: 50px;

      @media (max-width: $screen-xs-max) {
        padding-right: 0;
      }
    }
  }

  .splitted-component-secondary {
    border-left: 1px solid var(--theme-gray-eight);
    @apply transition-width;
    position: relative;
    max-width: 50%;

    @media (max-width: $screen-xs-max) {
      display: none;
    }

    .mat-tab-header {
      padding: 0;
    }

    &.-contentInside {
      width: 300px;
      flex-shrink: 0;

      @media (max-width: $screen-md-max) {
        width: 240px;
      }
    }

    .toggle-panel {
      position: absolute;
      top: 80px;
      left: -13px;
      @apply z-1;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: var(--site-main-one);
      color: var(--app-white);
      display: flex;
      align-items: center;
      justify-content: center;
      @apply cursor-pointer transition-transform;
    }

    &.-panelClosed {
      width: 0;

      .toggle-panel {
        display: none;
      }
    }
  }

  .splitted-component-detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    position: relative;

    .spinner_100_container {
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
    }
  }
}
