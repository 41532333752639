.dummy-animated {
  background: -moz-linear-gradient(
    left,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );
  background: linear-gradient(
    to right,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );

  background-size: 200% 200%;

  -webkit-animation: dummyGradient 1.5s linear infinite;
  -moz-animation: dummyGradient 1.5s linear infinite;
  animation: dummyGradient 1.5s linear infinite;
}

.dummy-rectangle {
  @apply h-8 w-full bg-theme-gray-three rounded;

  background: -moz-linear-gradient(
    left,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );
  background: linear-gradient(
    to right,
    var(--theme-gray-three) 0%,
    var(--theme-gray-three) 30%,
    var(--theme-gray-eighteen) 40%,
    var(--theme-gray-eighteen) 60%,
    var(--theme-gray-three) 70%,
    var(--theme-gray-three) 100%
  );

  background-size: 200% 200%;

  -webkit-animation: dummyGradient 1.5s linear infinite;
  -moz-animation: dummyGradient 1.5s linear infinite;
  animation: dummyGradient 1.5s linear infinite;
}

.dummy-background {
  @apply bg-theme-gray-three;
}

@-webkit-keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: -200% 50%;
  }
}

@-moz-keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: -200% 50%;
  }
}

@keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: -200% 50%;
  }
}
