.toast-container {
  &.toast-bottom-right {
    right: 85px;
    bottom: 16px;
    max-width: calc(100% - 95px);

    @media (max-width: $screen-xs-max) {
      right: 16px;
    }
  }

  .ngx-toastr {
    padding: 15px 35px 15px 50px;
    border-radius: 50px;
    box-shadow: none;
    width: auto;

    @media (max-width: $screen-xs-max) {
      border-radius: 20px;
    }

    &:hover {
      box-shadow: none;
    }
    &.toast-success {
      background-color: var(--app-green-three);
      background-image: url(/assets/images/svg-icons/icon-toast-success.svg);
    }
    &.toast-info {
      background-color: var(--site-main-one);
    }
    &.toast-warning {
      background-color: var(--app-red-one);
      background-image: url(/assets/images/svg-icons/icon-toast-error.svg);
    }
  }
}
