.logo_site_text {
  margin-left: 10px;
  font-family: 'Muli';
  font-weight: 700;

  font-size: 2rem;
  color: var(--site-main-one);

  &.-prepmir {
    font-family: 'Muli';
    font-weight: 800;

    font-size: 1.8rem;
    color: var(--theme-black);

    strong {
      font-family: Montserrat;
      font-size: 2rem;
      color: var(--site-main-one);
      font-weight: bold;
    }
  }
}
