@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/animations';

@import 'base/utils';
@import 'base/fonts';
@import 'base/global';

@tailwind base;

@import 'vendors/material-drawer.scss';
@import 'vendors/material-drag-drop.scss';
@import 'vendors/material-modals';
@import 'vendors/material-accordions';
@import 'vendors/material-paginator';
@import 'vendors/material-tabs';
@import 'vendors/xng-breadcrumb';
@import 'vendors/intl-tel-input';

@tailwind components;
@tailwind utilities;
@tailwind variants;

@import 'layout/section';
@import 'layout/containers';
@import 'layout/navbars';
@import 'layout/skeletton';
@import 'layout/mobile-device';
@import 'layout/mobile-browser';

@import 'components/admin';
@import 'components/cards-nnki';
@import 'components/progress';
@import 'components/toasts';
@import 'components/splitted_card';
@import 'components/blocks-nnki';
@import 'components/buttons-nnki';
@import 'components/dummy';
@import 'components/filters';
@import 'components/forms-nnki';
@import 'components/gradients';
@import 'components/modals';
@import 'components/tabs-nnki';
@import 'components/images';
@import 'components/spinners';
@import 'components/widgets-nnki';
@import 'components/log-table';
@import 'components/swipers';
@import 'components/directives';
@import 'components/vendors';
@import 'components/logo';
@import 'components/steppers';
@import 'components/zap';
@import 'components/landing';
@import 'components/writer-area';

@import 'pages/public-area';
@import 'pages/public-user';
