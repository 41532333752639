.cdk-overlay-pane {
  max-width: 100vw !important;
}

.cdk-overlay-dark-backdrop {
  @apply bg-gray-twentyfive;
}

.mat-dialog-container {
  text-align: center !important;
  background: transparent !important;
  box-shadow: none !important;
  max-height: 100vh !important;
  color: var(--theme-black);

  padding: 0 !important;
  border-radius: 0 !important;

  @screen sm {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: calc(var(--safe-area-inset-top) + 20px) !important;
    padding-bottom: calc(var(--safe-area-inset-bottom) + 20px) !important;
    border-radius: 6px !important;
  }

  > * {
    display: block;
    max-height: 100vh;
    overflow: auto;

    @screen sm {
      max-height: calc(100vh - 40px);
    }
  }
}
