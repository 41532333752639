.nnki-background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nnki-square-background-image {
  @extend .nnki-background-image;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.nnki-rounded-background-image {
  @extend .nnki-square-background-image;

  border-radius: 50%;
  width: 60px;
}

.user-image-sunray {
  position: relative;

  .sunray {
    background-image: url(/assets/images/backgrounds/sunray.png);
    background-size: 100%;
    width: 220px;
    height: 220px;

    position: absolute;
    top: -18px;
    left: -18px;
  }

  .nnki-rounded-background-image {
    width: 184px;
    position: relative;
  }

  .user-position {
    position: absolute;
    right: -10px;
    top: 20px;

    border-radius: 14px;
    background: var(--site-main-one);
    color: var(--app-white);
    font-size: 1.8rem;
    font-weight: 800;
    padding: 8px 12px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    line-height: 100%;

    svg-icon {
      margin-right: 5px;
    }
  }
}

.users-pictures-list {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 10px;

  .nnki-rounded-background-image {
    width: 37px;
    flex-shrink: 0;
    margin-left: -16px;
    border: 1px solid var(--theme-white);

    &:first-child {
      margin-left: 0;
    }
  }

  .user-plus {
    margin-left: -16px;
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    background: linear-gradient(
      144.11deg,
      var(--site-card-gradient-1) 0%,
      var(--site-card-gradient-2) 100%
    );
    color: var(--theme-white);
    font-size: 1.4rem;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &.-compressed {
    .nnki-rounded-background-image,
    .user-plus {
      margin-left: -23px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

svg-icon {
  @apply flex items-center justify-center;

  svg {
    height: auto;
  }
}
