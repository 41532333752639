app-writer-area {
  .mat-tab-nav-bar {
    @apply border-b border-b-site-theme-main-two #{!important};
  }

  .mat-tab-links {
    .mat-tab-link {
      @apply bg-theme-white border-t border-r border-site-theme-main-two text-site-main-one font-bold opacity-100;

      &:first-child {
        @apply border-l;
      }

      &.mat-tab-label-active {
        @apply text-site-main-one;
      }
    }
  }

  .mat-tab-content {
    @apply pt-8;
  }
}
